<template>
  <b-container fluid class="px-2">
    <hotkeys
      :shortcuts="['U']"
      @triggered="addCoupon"
    />
    <hotkeys
      :shortcuts="['E']"
      @triggered="saveCoupons"
    />
    <div class="auth-inner py-2">
      <b-card class="mb-0 center-layout">
        <b-row class="p-0" cols-xs="12">
          <b-col cols="6" sm="4" md="4">
            <img :src="logoImg" height="80">
          </b-col>
          <b-col cols="6" sm="8" lg="8">
            <h2 class="promo">
              {{ promotion.name }}
            </h2>
          </b-col>
        </b-row>

        <b-tabs v-model="tabIndex">
          <b-tab title="Dados Pessoais">
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserverParticipant"
            >
              <!-- Form -->
              <b-form
                @submit.prevent="handleSubmit(formSubmitted)"
              >
                <b-row>
                  <b-col md="4" sm="12">
                    <i2-form-input
                      v-model="model.document"
                      v-maska
                      label="CPF/CNPJ"
                      name="document"
                      rules="required|cpfCnpj"
                      data-maska="['###.###.###-##','##.###.###/####-##']"
                      placeholder="cpf/cnpj do participante"
                      @blur="getParticipantByDocument"
                    />
                  </b-col>
                  <b-col md="4" sm="12">
                    <i2-form-input
                      id="nome"
                      v-model="model.name"
                      name="nome"
                      label="Nome"
                      rules="required"
                      placeholder="Nome do participante"
                    />
                  </b-col>

                  <b-col md="4" sm="12">
                    <i2-form-input
                      v-model="model.email"
                      name="email"
                      label="E-mail"
                      rules="required|email"
                      placeholder="email@email.com"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6" sm="12">
                    <i2-form-input
                      v-model="model.phonenumber"
                      v-maska
                      label="Telefone"
                      name="phone"
                      rules="required"
                      data-maska="['(##) #####-####','(##) ####-####']"
                      placeholder="Telefone do participante"
                    />
                  </b-col>
                  <b-col md="6" sm="12">
                    <i2-form-input
                      v-model="model.city"
                      label="Cidade"
                      name="city"
                      rules="required"
                      placeholder="cidade"
                    />
                  </b-col>
                </b-row>
                <b-row>

                  <b-col cols="11" class="text-right">
                    <b-button variant="primary" @click="$router.push('/participants')">
                      Voltar
                    </b-button>
                    &nbsp;
                    <b-button @click="goToCoupons">
                      Salvar e enviar notas
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-tab>
          <b-tab title="Notas/Contratos" :disabled="isTabCouponsDisabled">
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserverCoupons"
            >
              <!-- Form -->
              <b-form
                ref="formCoupons"
                @submit.prevent="handleSubmit(saveCoupons)"
              >
                <b-row>
                  <b-col md="5" sm="12">
                    <i2-form-vue-select
                      id="type"
                      ref="typeSelect"
                      v-model="model.coupon.type"
                      uid="type"
                      name="type"
                      label="Tipo"
                      :options="couponsTyes"
                      rules="required"
                      :tabindex="0"
                      placeholder="tipo do documento"
                    />
                  </b-col>

                  <b-col md="7" sm="12">
                    <i2-form-vue-select
                      id="nome"
                      ref="companySelect"
                      v-model="model.coupon.company"
                      name="type"
                      label="Tipo"

                      :options="companyList"
                      rules="required"
                      placeholder="Empresa"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col v-if="isCoupon" md="3" sm="3">
                    <i2-form-input
                      v-model="model.coupon.serie"
                      label="Série da Nota"
                      name="serie"
                      rules="required"
                      placeholder="Serie"
                    />
                  </b-col>
                  <b-col v-if="isCoupon" md="3" sm="3">
                    <i2-form-input
                      v-model="model.coupon.number"
                      label="Número da Nota"
                      name="number"
                      rules="required"
                      placeholder="Número da nota"
                    />
                  </b-col>
                  <b-col v-if="!isCoupon" md="5" sm="5">
                    <i2-form-input
                      v-model="model.coupon.number"
                      label="Número do Contrato"
                      rules="required"
                      name="contract_number"
                      placeholder="Número do contrato"
                    />
                  </b-col>
                  <b-col md="3" sm="3">
                    <i2-form-input
                      v-model="model.coupon.date"
                      label="Data do cupom"
                      type="date"
                      name="coupom_date"
                      rules="required"
                      placeholder="Data do cupom"
                    />
                  </b-col>
                  <b-col md="3" sm="3">
                    <i2-form-input-money
                      v-model="model.coupon.value"
                      label="Valor"
                      rules="required"
                      name="valor_cupom"
                      placeholder="Valor"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="5" sm="6">
                    <i2-form-input-file
                      id="files"
                      ref="files"
                      v-model="model.coupon.image"
                      label="Cupom ou Contrato"
                      :accept="acceptFiles"
                      name="Arquivo do documento"
                      :multiple="!isCoupon && !isPdf"
                      placeholder="Arquivo"
                      rules="required"
                    />
                  </b-col>
                  <b-col v-if="!isMobile" align-self="center" cols="3">
                    <b-button block variant="outline-secondary" @click="addCoupon">
                      <feather-icon icon="PlusIcon"/>
                      Adicionar
                    </b-button>
                  </b-col>
                  <b-col v-if="!isMobile" align-self="center" cols="3">
                    <b-button block variant="primary" @click="saveCoupons">
                      <feather-icon icon="SaveIcon" class="mr-1"/>
                      <u>E</u>nviar Notas
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="isMobile" class="pb-1">
                  <b-col align-self="center">
                    <b-button block variant="primary" @click="addCoupon">
                      Adicionar
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="isMobile" class="pb-1">
                  <b-col align-self="center">
                    <b-button block variant="info" @click="saveCoupons">
                      Enviar Notas/Contrato
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-form-checkbox
                      v-model="isPdf"
                      :checked="isPdf"
                      name="check-button"
                      switch
                    >
                      Enviar PDF
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <i2-divider/>
                <b-row>
                  <b-col sm="12">
                    <h3>Total: {{ totalCouponsValue | toCurrency }}</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-table
                      :items="model.coupons"
                      responsive
                      :fields="fields"
                      class="mb-0"
                    >
                      <template #cell(company)="data">
                        {{ getCompany(data.item.company_document) }}
                      </template>

                      <template #cell(value)="data">
                        {{ data.item.value | toCurrency }}
                      </template>
                      <template #cell(type)="data">
                        <span v-if="data.item.type === 'coupon'">
                          Cupom/Nota fiscal
                        </span>
                        <span v-if="data.item.type === 'contract'">
                          Contrato
                        </span>
                      </template>

                      <template #cell(numero)="data">
                        <span v-if="data.item.type === 'coupon'">
                          Série: {{ data.item.serie }} - Número: {{ data.item.number }}
                        </span>
                        <span v-if="data.item.type === 'contract'">
                          {{ data.item.number }}
                        </span>
                      </template>
                      <template #cell(action)="data">
                        <i2-icon-button icon="TrashIcon" variant="danger" @click="removeCoupon(data)"/>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-tab>
        </b-tabs>
      </b-card>

    </div>
  </b-container>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationObserver } from 'vee-validate'
import { BCard, BTable } from 'bootstrap-vue'
import Hotkeys from '@/components/i2/content/HotKeys.vue'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'
import { mapGetters } from 'vuex'
import { vMaska } from 'maska'
import I2Divider from '@/components/i2/content/i2Divider.vue'

export default {
  name: 'UserCouponForm',
  components: {
    I2Divider,
    I2FormInputFile,
    ValidationObserver,
    BTable,
    Hotkeys,
  },
  directives: { maska: vMaska },
  data() {
    return {
      promotion: {
        uuid: '',
        name: '',
      },
      uuid: '',
      logoImg: '',
      isMobile: false,
      tabIndex: 0,
      currentPromotion: null,
      isTabCouponsDisabled: true,
      couponsTyes: [
        {
          value: 'coupon',
          text: 'Cupom/Nota fiscal',
        },
        {
          value: 'contract',
          text: 'Contrato',
        },
      ],
      companyList: [],
      itemsTeste: [],
      totalCouponsValue: 0,
      isPdf: false,
      acceptFiles: 'image/*',
      model: {
        name: '',
        email: '',
        document: '',
        city: '',
        phonenumber: '',
        coupons: [],
        coupon: {
          type: null,
          company: null,
          contract_number: '',
          serie: null,
          number: null,
          value: '',
          date: '',
          image: [],
        },
      },
      companies: [],
      fields: [
        {
          key: 'company',
          label: 'Empresa',
        },
        {
          key: 'type',
          label: 'tipo',
        },
        {
          key: 'numero',
          label: 'Número',
        },
        {
          key: 'value',
          label: 'Valor',
        },
        {
          key: 'status',
          label: 'Situação',
        },
        {
          key: 'action',
          label: 'Ação',
        },
      ],
    }
  },
  computed: {
    isCoupon() {
      return this.model.coupon.type?.value === 'coupon'
    },
  },
  watch: {
    isPdf(value) {
      this.acceptFiles = 'image/*'
      if (value) {
        this.acceptFiles = '.pdf'
      }
    },
  },
  async mounted() {
    this.tabIndex = 0
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      await this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }
    this.currentPromotion = promotion
    await this.getCompanies(promotion.uuid)
    this.isMobile = this.currentBreakPoint() === 'sm' || this.currentBreakPoint() === 'xs'
    this.model.coupon.type = this.couponsTyes.find(item => item.value === 'coupon')
  },
  methods: {
    ...mapGetters('app', ['currentBreakPoint']),

    async getCompanies(uuid) {
      const companies = await this.$http.get(`/promotions/${uuid}/company-available`)
      this.companyList = companies.map(item => ({
        text: item.company_name,
        value: item.document,
      }))
    },
    formSubmitted() {

    },
    onSubmit() {

    },
    async addCoupon() {
      const validate = await this.$refs.refFormObserverCoupons.validate()
      if (!validate) {
        this.$notify.error('Informe todos os dados do cupom.')
        return
      }
      let coupon = {
        company_document: this.model.coupon.company.value,
        serie: this.model.coupon.serie,
        city: this.model.city,
        phone: this.model.phonenumber,
        number: this.model.coupon.number,
        type: this.model.coupon.type.value,
        value: this.model.coupon.value,
        status: 'Não enviado',
        date: this.model.coupon.date,
        images: this.model.coupon.image,
      }

      const exists = this.model.coupons.find(item => item.company_document === coupon.company_document && item.serie === coupon.serie && item.number === coupon.number)
      if (exists) {
        this.$notify.error('Esse cupom já foi adicionado!')
        return
      }

      this.model.coupons.push(coupon)
      this.refreshTotalValue()
      this.resetCoupon()
      this.$refs.typeSelect.setFocus()
      this.$notify.success('Cupom adicionado a lista.')
    },

    async goToCoupons() {
      const validate = await this.$refs.refFormObserverParticipant.validate()
      if (!validate) {
        this.$notify.error('Informe todos os dados do participante')
        return
      }
      this.isTabCouponsDisabled = false
      this.$nextTick(() => {
        this.tabIndex = 1
      })
      this.resetCoupon()
      this.model.coupons = []
    },
    getCompany(cpnj) {
      const company = this.companyList.find(item => item.value === cpnj)
      return company.text
    },
    async saveCoupons() {
      const validate = await this.$refs.refFormObserverParticipant.validate()
      if (!validate) {
        this.$notify.error('Informe todos os dados do participante')
        this.$nextTick(() => {
          this.tabIndex = 0
        })
        return
      }
      if (this.model.coupons.length === 0) {
        this.$notify.error('Ao menos uma nota deve ser informada.')
        return
      }
      let data = {
        name: this.model.name,
        email: this.model.email,
        document: this.model.document,
        phone: this.model.phonenumber,
        city: this.model.city,
        items: [],
      }
      for (const coupon of this.model.coupons) {
        let couponData = {
          company_cnpj: coupon.company_document,
          serie_coupon: coupon.serie,
          number_coupon: coupon.number,
          date: coupon.date,
          value: coupon.value,
          type: coupon.type,
          file: [],
        }

        let { images } = coupon
        if (!Array.isArray(images)) {
          images = [coupon.images]
        }

        for (const file of images) {
          let fileData = {
            name: file.name,
            extension: file.extension,
            content: await this.toBase64(file),
          }
          couponData.file.push(fileData)
        }
        data.items.push(couponData)
      }

      const result = await this.$http.post(`/promotions/${this.currentPromotion.uuid}/coupons/batch`, data)
      let message = 'Notas Enviadas com sucesso!'
      if (result.error) {
        message = 'Suas notas não foram enviadas. Verifique o motivo em cada nota.'
        this.$notify.error(message)
        if (result.id === 'C32') {
          result.errors.forEach(error => {
            this.model.coupons[error.coupom].status = error.error
          })
        }
        return
      }

      this.totalCouponsValue = 0
      let answer = await this.$bvModal.msgBoxConfirm(message, {
        title: 'Lançar novo Participante',
        okVariant: 'success',
        cancelVariant: 'info',
        okTitle: 'Sim',
        cancelTitle: 'Não',
      })
      if (answer) {
        this.newSubmission()
      }
    },

    newSubmission() {
      this.resetCoupon()
      this.model.name = null
      this.model.email = null
      this.model.document = null
      this.model.city = null
      this.model.phonenumber = null
      this.model.coupons = []
      this.refreshTotalValue()
      this.isTabCouponsDisabled = true
      this.$refs.refFormObserverParticipant.reset()
      this.$nextTick(() => {
        this.tabIndex = 0
      })
    },
    resetCoupon() {
      this.model.coupon.image = null
      this.model.coupon.serie = null
      this.model.coupon.number = null
      this.model.coupon.value = ''
      this.model.coupon.company = null
      this.model.coupon.type = this.couponsTyes.find(item => item.value === 'coupon')
      this.$refs.refFormObserverCoupons.reset()
    },
    removeCoupon(dataItem) {
      this.model.coupons = this.model.coupons.filter((item, index) => index !== dataItem.index)
      this.refreshTotalValue()
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split(',')[1])
        reader.onerror = error => reject(error)
      })
    },
    async getParticipantByDocument() {
      const document = this.model.document.replace(/\D/g, '')
      const result = await this.$http.get(`/participants/${document}`)
      if (result.error) {
        return
      }
      this.model.name = result.name
      this.model.email = result.email
      this.model.city = result.city
      this.model.phonenumber = result.phone
    },
    refreshTotalValue() {
      let total = 0
      this.model.coupons.forEach(coupon => total += parseFloat(coupon.value))
      this.totalCouponsValue = total
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
